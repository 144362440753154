import { BehaviorSubject } from "rxjs";
import { DataObject, DataObjectSubject } from "../types/types";

const definedFlags = {
    isProduction: "isProduction",
    isLocal: "isLocal",
    currentEnvLink: "currentEnvLink"
};

export class SharedFlags {

    private flags: DataObject;
    private flagsBS: DataObjectSubject;

    constructor() {
        this.flags = {
            [definedFlags.isProduction]: true,
            [definedFlags.isLocal]: false,
            [definedFlags.currentEnvLink]: location.host
        };

        this.flagsBS = {};

        this.checkForProdOrLocal();
    }

    // check whether the running env is local or prod
    private checkForProdOrLocal() {
        const envLink = <string>(this.getFlag(definedFlags.currentEnvLink));
        const isLocal = envLink.includes("localhost") || envLink.includes("127.0.0.1");
        this._setFlag(definedFlags.isLocal, isLocal);
        this._setFlag(definedFlags.isProduction, !isLocal);
    }

    private _setFlag(key: string, value: any) {
        this.flags[key] = value;
    }

    public getFlag(key: string) {
        return this.flags[key];
    }

    public setFlag(key: string, value: any) {
        if (definedFlags[key]) return;
        this._setFlag(key, value);
    }

    private _setFlagBS(key: string, value: any) {
        this.flagsBS[key] = new BehaviorSubject(value);
    }

    public getFlagBS(key: string) {
        if (!(key in this.flagsBS)) {
            this._setFlagBS(key, null);
        }
        return this.flagsBS[key].asObservable();
    }

    public setFlagBS(key: string, value: any) {
        if (this.flagsBS[key]) {
            this.flagsBS[key].next(value);
        } else {
            this._setFlagBS(key, value);
        }
    }

    public getFlags() {
        return this.flags;
    }
}
