class UserAuth {

    private _userData: any;
    private isAuthenticated: boolean;
    private credData: any;

    constructor() {
        this._userData = {};
        this.isAuthenticated = false;
        this.credData = {};
    }

    setIsAuthenticated(value: boolean) {
        this.isAuthenticated = value;
    }

    getIsAuthenticated() {
        return this.isAuthenticated;
    }

    setUserData(userData: any) {
        this._userData = userData;
    }

    getUserData() {
        return this._userData;
    }

    setUserCredData(userCredData: any) {
        this.credData = userCredData;
    }

    getUserCredData() {
        return this.credData;
    }
}

export const userAuthState = new UserAuth();
