import { Subject } from "rxjs";

class NavigationState  {

    private $navigation: Subject<any>;

    constructor() {
        this.$navigation = new Subject();
    }

    // get navigation url/array
    public getNavigation() {
        return this.$navigation.asObservable();
    }

    // set new navigation url/array
    public setNavigation(value: any) {
        this.$navigation.next(value);
    }
}

export const navigationState = new NavigationState();
