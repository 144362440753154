import { BehaviorSubject } from "rxjs";
import { DataObject, DataObjectSubject } from "../types/types";
import { SharedFlags } from "./flags";

class SharedState extends SharedFlags {

    private _data: DataObject;
    private $dataSub: DataObjectSubject;
    private eventsCBData: any;

    constructor() {
        super();
        this._data = {};
        this.$dataSub = {};
        this.eventsCBData = {};
    }

    // get the object value using the key from simple data object
    public getApplicationData(key: string) {
        return this._data[key] || {};
    }

    // set the object value as using the key to simple data object
    public setApplicationData(key: string, value: any) {
        this._data[key] = value;
    }

    // get the object value as Observable using the key from data subject
    // or set the BS if the key is not there
    public getApplicationDataSub(key: string) {
        if (!this.$dataSub[key]) {
            this.setApplicationDataSub(key, {});
        }
        return this.$dataSub[key].asObservable();
    }

    // set the object value as BehaviorSubject using the key to data subject
    public setApplicationDataSub(key: string, value: any) {
        if (this.$dataSub[key]) {
            this.$dataSub[key].next(value);
        } else {
            this.$dataSub[key] = new BehaviorSubject(value);
        }
    }

    public getApplicationDataEvent(key: string, cb: any) {
        window.addEventListener(key, cb);
    }

    public setApplicationDataEvent(key: string, value: any) {
        if (this.eventsCBData[key]) {
            return console.warn("An event is already defined for the key");
        } else {
            const event = new CustomEvent(key, {
                detail: {
                    data: value
                }
            });
            this.eventsCBData[key] = true;
            window.dispatchEvent(event);
            return () => window.dispatchEvent(event);
        }
    }

    public removeApplicationDataEvent(key: string, cb: any) {
        window.removeEventListener(key, cb);
    }
}

export const sharedState = new SharedState();
